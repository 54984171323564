import type { FC } from "react";
import React from "react";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import PharmacyForm from "../../PharmacyForm/PharmacyForm";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import translate from "src/lib/translate";

const SelectPharmacy: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleSaved = () => {
    tracker.track("Pharmacy Information Added/Updated" as TrackEvent);
    AppQueryPopupsController.closePopup();
  };

  return (
    <InDialog
      popup
      title={translate("yourPharmacy")}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
    >
      <PharmacyForm onPharmacySaved={handleSaved} />
    </InDialog>
  );
};

export default SelectPharmacy;
